define("discourse/plugins/discourse-onesignal/initializers/component-overrides", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "onesignal-component-overrides",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.modifyClass("component:d-modal", {
          init() {
            this._super(...arguments);
            if (document.body.classList.contains("mobile-app-login-modal")) {
              this.set("dismissable", false);
            }
          },
          mouseDown(e) {
            if (document.body.classList.contains("mobile-app-login-modal")) {
              return;
            }
            this._super(...arguments);
          }
        });
      });
    }
  };
});