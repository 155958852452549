define("discourse/plugins/discourse-onesignal/initializers/msg-rn-app", ["exports", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/models/user"], function (_exports, _ajax, _utilities, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "msg-rn-app",
    after: "inject-objects",
    initialize(container) {
      const currentUser = container.lookup("current-user:main");
      const capabilities = container.lookup("capabilities:main");
      if (capabilities.isAppWebview && currentUser) {
        (0, _utilities.postRNWebviewMessage)("currentUsername", currentUser.username);
      }

      // called by webview
      window.DiscourseOnesignal = {
        subscribeDeviceToken(token, platform, application_name) {
          (0, _ajax.ajax)("/onesignal/subscribe.json", {
            type: "POST",
            data: {
              token: token,
              platform: platform,
              application_name: application_name
            }
          }).then(result => {
            (0, _utilities.postRNWebviewMessage)("subscribedToken", result);
          });
        }
      };
    }
  };
});