define("discourse/plugins/discourse-onesignal/discourse/routes/onesignal-app-login", ["exports", "@ember/runloop", "@ember/object/evented", "@ember/routing/route"], function (_exports, _runloop, _evented, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    afterModel(model, transition) {
      if (!this.currentUser) {
        (0, _runloop.next)(() => transition.send("showLogin"));
      } else {
        (0, _runloop.next)(() => this.transitionTo("discovery.latest"));
      }
    },
    addBodyClass: (0, _evented.on)("activate", function () {
      if (!this.currentUser) {
        document.body.classList.add("mobile-app-login-modal");
      }
    }),
    removeBodyClass: (0, _evented.on)("deactivate", function () {
      if (!this.currentUser) {
        (0, _runloop.later)(() => document.body.classList.remove("mobile-app-login-modal"), 300);
      }
    })
  });
});